.toggle-switch-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: default;
    // pointer-events: none;
  }
  .toggle-switch-label {
    color: #777;
    padding-right: 5px;
  }
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  .toggle-input {
    display: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    &:focus {
      outline: 0;
    }
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 17px;
  }

  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .toggle-slider {
    background-color: #4281a6;
  }

  input:checked + .disabled.toggle-slider {
    background-color: #7b7b7c;
  }

  .disabled.toggle-slider {
    background-color: #7b7b7c;
  }

  input:focus + .toggle-slider {
    box-shadow: 0 0 1px #4281a6;
  }

  input:checked + .toggle-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
}
